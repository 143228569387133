.autocomplete {
  position: relative;
  text-align: center;
}

#food-input {
  width: 71.5%;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: solid;
  border-radius: 0px;
  border-color: grey;
}

#food-submit {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #000000;
  background-color: #f3f3f3;
  border: solid;
  border-radius: 0px;
  transition: background-color 0.3s ease;
}

.suggestions {
  position: absolute;
  top: 100%;
  text-align: center;
  max-height: 200px; 
  width: 82.8%;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable vertical scroll when content exceeds max-height */
  display: block;
  margin-left: 8.5%;
}

.suggestions li {
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f5f5f5;
}

.dropdown-wrapper {
  text-align: center;
  margin-top: .5vw;
}